<template>
  <div class="general--btn" :class="[type, { disabled }]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "generalBtn",
  props: {
    type: { type: String },
    disabled: { type: Boolean, default: false },
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";

.general--btn {
  border-radius: rem(69);
  padding: rem(13);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &.regular {
    background: color(balance-green);
    color: color(white);

    &.disabled {
      background: color(balance-green);
      color: color(white);
      opacity: 0.6;
    }
  }

  &.secondary {
    color: color(balance-green);
    border: rem(1) solid color(balance-green);
  }

  &.secondaryGray {
    color: color(gray2);
    border: rem(1) solid color(gray2);
    background-color: color(white);

    &.disabled {
      border-color: color(light-gray);
    }
  }

  &.secondaryWhite {
    color: color(balance-green);
    background-color: color(white);
  }

  &.whiteTextnBorder {
      color: color(white);
      border: rem(1) solid color(white);
  }

  &.disabled {
    pointer-events: none;
    background-color: color(light-gray);
    color: color(gray);

    &:hover {
      cursor: not-allowed;
    }
  }
}
</style>
